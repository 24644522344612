import { injectable } from "inversify";
import AIRepository from "../../repositories/AIRepository";

interface props {
    aiRepository: AIRepository;
}

@injectable()
export class GetNewAIEvaluationUseCase {
    _aiRepository: AIRepository;

    constructor(_: props) {
        this._aiRepository = _.aiRepository;
    }

    public call = async (record: string): Promise<any> => {
        return await this._aiRepository.getNewAIEvaluation(record);
    }
}

export const GetNewAIEvaluationUseCaseName = "GetNewAIEvaluationUseCaseName";